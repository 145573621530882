import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import * as styles from "./styles.module.scss";

const RightContent = ({image}) => {
  const styler = new StyleExtractor(styles);

  return (
    <div className={styler.get("rightContentWrap")}>
      <GatsbyImage
        className={styler.get("image")}
        alt={image?.alt || "hero image"}
        image={image?.image?.asset?.gatsbyImageData}
        objectFit='contain'
      />
    </div>
  );
};

export default RightContent;
