import React from "react";

export const StripedDesignElement = ({
  width = 310,
  className,
  color = "#58ACF0",
}) => {
  return (
    <svg
      width={width}
      className={className}
      color={color}
      viewBox='0 0 310 41'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_6667_13601'
        style={{maskType: "alpha"}}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='341'
        height='41'
      >
        <rect width='341' height='41' fill='currentColor' />
      </mask>
      <g mask='url(#mask0_6667_13601)'>
        <line
          x1='-11.676'
          y1='72.2322'
          x2='78.6769'
          y2='-18.1207'
          stroke='currentColor'
          strokeWidth='6'
        />
        <line
          x1='14.6775'
          y1='72.2322'
          x2='105.03'
          y2='-18.1207'
          stroke='currentColor'
          strokeWidth='6'
        />
        <line
          x1='41.031'
          y1='72.2322'
          x2='131.384'
          y2='-18.1207'
          stroke='currentColor'
          strokeWidth='6'
        />
        <line
          x1='67.3826'
          y1='72.2322'
          x2='157.736'
          y2='-18.1207'
          stroke='currentColor'
          strokeWidth='6'
        />
        <line
          x1='93.7341'
          y1='72.2322'
          x2='184.087'
          y2='-18.1207'
          stroke='currentColor'
          strokeWidth='6'
        />
        <line
          x1='120.088'
          y1='72.2322'
          x2='210.441'
          y2='-18.1207'
          stroke='currentColor'
          strokeWidth='6'
        />
        <line
          x1='146.441'
          y1='72.2322'
          x2='236.794'
          y2='-18.1207'
          stroke='currentColor'
          strokeWidth='6'
        />
        <line
          x1='172.793'
          y1='72.2322'
          x2='263.146'
          y2='-18.1207'
          stroke='currentColor'
          strokeWidth='6'
        />
        <line
          x1='199.144'
          y1='72.2322'
          x2='289.497'
          y2='-18.1207'
          stroke='currentColor'
          strokeWidth='6'
        />
        <line
          x1='225.498'
          y1='72.2322'
          x2='315.851'
          y2='-18.1207'
          stroke='currentColor'
          strokeWidth='6'
        />
        <line
          x1='251.851'
          y1='72.2322'
          x2='342.204'
          y2='-18.1207'
          stroke='currentColor'
          strokeWidth='6'
        />
      </g>
    </svg>
  );
};

export const DottedDesignElement = ({
  width = 341,
  className,
  color = "#58ACF0",
}) => {
  return (
    <svg
      width={width}
      className={className}
      color={color}
      viewBox='0 0 341 62'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_6667_13563'
        style={{maskType: "alpha"}}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='341'
        height='49'
      >
        <rect
          width='341'
          height='49'
          transform='matrix(-1 0 0 1 341 0)'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_6667_13563)'>
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 297.727 14.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 273.383 14.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 248.824 14.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 224.266 14.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 199.709 14.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 175.152 14.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 150.594 14.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 126.035 14.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 101.477 14.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 76.9199 14.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 52.3633 14.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 288.727 32.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 264.383 32.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 239.824 32.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 215.266 32.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 190.709 32.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 166.152 32.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 141.594 32.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 117.035 32.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 92.4766 32.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 67.9199 32.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 43.3633 32.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <mask
        id='mask1_6667_13563'
        style={{maskType: "alpha"}}
        maskUnits='userSpaceOnUse'
        x='0'
        y='27'
        width='341'
        height='35'
      >
        <rect
          width='341'
          height='35'
          transform='matrix(-1 0 0 1 341 27)'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_6667_13563)'>
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 297.727 53.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 273.383 53.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 248.824 53.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 224.266 53.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 199.709 53.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 175.152 53.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 150.594 53.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 126.035 53.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 101.477 53.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 76.9199 53.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='2'
          y1='-2'
          x2='-1'
          y2='-2'
          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 52.3633 53.6191)'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
