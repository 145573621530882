import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import {
  DottedDesignElement,
  StripedDesignElement,
} from "../../../../icons/DesignElements";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const Quote = ({data}) => {
  const {quote, designation, author, image} = data || {};

  return (
    <div className={styler.get("quoteWrap", "full-bleed")}>
      <div className={styler.get("quoteContainer")}>
        <div className={styler.get("quote")}>
          <p>{quote}</p>
          <div className={styler.get("cite")}>
            <div className={styler.get("author")}>{author}</div>
            <div className={styler.get("designation")}>{designation}</div>
          </div>
        </div>
        {image?.image?.asset?.gatsbyImageData && (
          <div className={styler.get("imageWrapper")}>
            <GatsbyImage
              className={styler.get("image")}
              image={image?.image?.asset?.gatsbyImageData}
              alt={image?.alt || "quote image"}
              objectFit='contain'
            />
            <StripedDesignElement className={styler.get("topDesignElement")} />
            <DottedDesignElement
              className={styler.get("bottomDesignElement")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Quote;
