import {StyleExtractor} from "@kubric/utils";
import React from "react";

import * as styles from "./styles.module.scss";

const Hero = ({data}) => {
  const styler = new StyleExtractor(styles);
  const {heading, description} = data;

  return (
    <div className={styler.get("heroWrap", "full-bleed")}>
      <h1 className={styler.get("heading")}>{heading}</h1>
      <p className={styler.get("description")}>{description}</p>
    </div>
  );
};

export default Hero;
