import {graphql} from "gatsby";
import React from "react";

import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import Main from "../../components/pages/fieldGuide/Main";
import Hero from "../../components/pages/fieldGuide/homePage/Hero";
import Quote from "../../components/pages/fieldGuide/homePage/Quote";
import RightContent from "../../components/pages/fieldGuide/homePage/RightContent";
import "../../pages/styles.scss";

const FieldGuidePage = ({data, pageContext: {data: cmsData}}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];
  const {hero, stickyNav, quote, mainRightImage} = cmsData;

  return (
    <Layout
      type='modemagic'
      mmv3
      seoData={seoData}
      headerProps={{showMMCta: true, alternateBg: true}}
    >
      <StructuredData />
      <Buffer buffer={66} mobileBuffer={30} />
      <Hero data={hero} />
      <Main
        stickyNav={stickyNav}
        rightContent={<RightContent image={mainRightImage} />}
        home
      />
      <Quote data={quote} />
    </Layout>
  );
};

export const query = graphql`
  query ($tag: String) {
    allSanitySeoMeta(filter: {page: {eq: $tag}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;

export default FieldGuidePage;
