// extracted by mini-css-extract-plugin
export var author = "styles-module--author--3LFLX";
export var bottomDesignElement = "styles-module--bottomDesignElement--30j0X";
export var cite = "styles-module--cite--gX2Di";
export var designation = "styles-module--designation--3zuSO";
export var image = "styles-module--image--3LNHw";
export var imageWrapper = "styles-module--imageWrapper--108XS";
export var quote = "styles-module--quote--3lkGF";
export var quoteContainer = "styles-module--quoteContainer--2_EEe";
export var quoteWrap = "styles-module--quoteWrap--1k93d";
export var topDesignElement = "styles-module--topDesignElement--3DvZd";